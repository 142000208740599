<template>
  <b-modal
    ref="modalCourseEdit"
    :visible="editCourseActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`Edit Course`"
    ok-title="Save Changes"
    centered
    body-class="pt-0"
    @ok="saveChanges"
  >
    <b-tabs v-model="tabCurrentIndex" content-class="mt-3" justified>
      <b-tab title="Roster" active>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-form-group label="Course Name" label-for="course-name">
              <b-form-input required autofocus id="course-name" placeholder="" v-model="courseEditData.standard.name" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <b-form-group label="Course Shortname" label-for="course-shortname">
              <b-form-input required autofocus id="course-shortname" placeholder="" v-model="courseEditData.standard.shortName" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <b-form-group label="Course Learn ID" label-for="course-learnId">
              <b-form-input required autofocus id="course-learnId" placeholder="" v-model="courseEditData.standard.metadata.learnId" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <b-form-group label="Course Avatar URL" label-for="course-avatar">
              <b-form-input required autofocus id="course-avatar" placeholder="" v-model="courseEditData.standard.avatar" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-form-group label="Course Hours" label-for="course-hours">
              <b-form-input required autofocus id="course-hours" placeholder="" v-model="courseEditData.standard.hours" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-form-group label="Course Cost" label-for="course-cost">
              <b-form-input required autofocus id="course-cost" placeholder="" v-model="courseEditData.standard.cost" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-form-group label="Course Color HEX" label-for="course-color">
              <b-form-input
                required
                autofocus
                id="course-color"
                v-model="courseEditData.standard.metadata.design.color"
                @input="handleColorInput"
                :state="isColorValid === false ? false : null"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-form-group label="Visibility" label-for="course-visibility">
              <v-select
                v-model="courseEditData.standard.visibility"
                :options="visibilityOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                required
                transition="smooth"
                input-id="course-visibility"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-form-group label="Status" label-for="course-status">
              <v-select
                v-model="courseEditData.standard.status"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                required
                transition="smooth"
                input-id="course-status"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab v-if="$can('roster.course.compliance.update')" title="Compliance">
        <b-form>
          <b-form-group label="Scoring & Reporting" label-for="compliance-enabled">
            <b-form-checkbox
              id="compliance-enabled"
              :checked="courseEditData.compliance.enabled"
              @change="(val) => (courseEditData.compliance.enabled = val)"
              switch
              class="custom-control-primary"
            >
              {{ courseEditData.compliance.enabled ? 'Enabled' : 'Disabled' }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            v-if="courseEditData.compliance.enabled"
            label-cols-lg="3"
            label="Score Weights"
            label-size="lg"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-group label="Attendance:" label-for="compliance-weight-attendance" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseEditData.compliance.weights.attendance"
                id="compliance-weight-attendance"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Exam Edge" label-for="compliance-weight-examedge" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseEditData.compliance.weights.examedge"
                id="compliance-weight-examedge"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Simulations" label-for="compliance-weight-simulations" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseEditData.compliance.weights.simulations"
                id="compliance-weight-simulations"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Labs" label-for="compliance-weight-labs" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseEditData.compliance.weights.labs"
                id="compliance-weight-labs"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Labs (external)" label-for="compliance-weight-labs-external" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseEditData.compliance.weights.labs_external"
                id="compliance-weight-labs-external"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Other" label-for="compliance-weight-other" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseEditData.compliance.weights.other"
                id="compliance-weight-other"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>
          </b-form-group>
        </b-form>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BModal,
  BRow,
  VBModal,
  BTab,
  BTabs,
  BFormRadioGroup,
  BFormCheckbox,
  BCard,
} from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import { validateHexColor } from '@/libs/utils/color';
import { ref, onUnmounted } from '@vue/composition-api/dist/vue-composition-api';
import store from '@/store';
import placementStoreModule from '@/views/apps/placement/placementStoreModule';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useCourses from '../useCourses';
import vSelect from 'vue-select';
import _ from 'lodash';

export default {
  name: 'CourseEditModal',
  components: {
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BModal,
    quillEditor,
    vSelect,
    BTabs,
    BTab,
    BFormRadioGroup,
  },
  directives: { 'b-modal': VBModal },
  props: {
    editCourseActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    courseData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isColorValid: null,
      tabCurrentIndex: 0,
      courseEditData: { standard: undefined, compliance: undefined },
    };
  },
  watch: {
    '$props.editCourseActive'(val) {
      this.initModal();
    },
    '$props.courseData'(val) {
      this.initModal();
    },
  },
  created() {
    this.initModal();
  },
  methods: {
    initModal() {
      if (this.$props.courseData) {
        const { name, shortName, avatar, hours, cost, visibility, id, status, compliance, metadata } = _.cloneDeep(this.$props.courseData);
        this.courseEditData.standard = {
          name,
          shortName,
          avatar,
          visibility,
          id,
          status,
          hours,
          cost,
          metadata: {
            design: {
              color: '',
            },
            ...metadata,
          },
        };
        this.courseEditData.compliance = compliance;
      }
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:edit-course-active', false);
    },
    handleColorInput() {
      const color = this.courseEditData.standard.metadata.design.color;
      this.isColorValid = validateHexColor(color);
    },
    saveChanges(event) {
      this.busy = true;
      event.preventDefault();

      this.handleColorInput();
      if (!this.isColorValid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invalid HEX Color',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Please enter a valid hex color code',
          },
        });
        this.busy = false;
        return;
      }

      if (this.tabCurrentIndex === 0) {
        const pl = { ...this.courseEditData.standard };

        store
          .dispatch('rosterStoreModule/updateCourse', { courseId: pl.id, courseData: pl })
          .then((response) => {
            // this.$emit('update:course-data', response.data);

            this.busy = false;
            this.$emit('refetch-data');
            this.closeModal();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Course Edited!',
                icon: 'EditIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.busy = false;
            this.$emit('refetch-data');
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to edit course',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.response ? error.response.data.message : error.message,
              },
            });
          });
      } else if (this.tabCurrentIndex === 1) {
        const pl = { enabled: this.courseEditData.compliance.enabled, weights: this.courseEditData.compliance.weights };
        Object.keys(pl.weights).forEach((key) => (pl.weights[key] = parseFloat(pl.weights[key])));

        store
          .dispatch('rosterStoreModule/updateCourseCompliance', { courseId: this.courseData.id, complianceData: pl })
          .then((response) => {
            // this.$emit('update:course-data', response.data);

            this.busy = false;
            this.$emit('refetch-data');
            this.closeModal();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Compliance Edited!',
                icon: 'EditIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.busy = false;
            this.$emit('refetch-data');
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to edit course',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.response ? error.response.data.message : error.message,
              },
            });
          });
      }
    },
  },
  setup(props) {
    const PLACEMENT_STORE_NAME = 'placementStoreModule';

    const busy = ref(false);

    const { statusOptions, visibilityOptions } = useCourses();

    return { statusOptions, visibilityOptions, busy };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
