<template>
  <b-modal
    ref="modalCreateCourse"
    :visible="createCourseActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`New Course`"
    ok-title="Create Course"
    centered
    body-class="pt-0"
    @ok="createEntity"
  >
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-form-group label="Course Name" label-for="course-name">
          <b-form-input required autofocus id="course-name" placeholder="" v-model="courseEditData.name" />
        </b-form-group>
      </b-col>
      <!-- <b-col cols="12" md="8" lg="8">
        <b-form-group label="Course Avatar URL" label-for="course-avatar">
          <b-form-input required id="course-avatar" placeholder="" v-model="courseEditData.avatar" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Course Color HEX" label-for="course-color">
          <b-form-input required id="course-color" placeholder="" v-model="courseEditData.metadata.design.color" />
        </b-form-group>
      </b-col> -->
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Visibility" label-for="course-visibility">
          <v-select
            v-model="courseEditData.visibility"
            :options="visibilityOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            required
            transition="smooth"
            input-id="course-visibility"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Status" label-for="course-status">
          <v-select
            v-model="courseEditData.status"
            :options="statusOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            required
            transition="smooth"
            input-id="course-status"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BCol, BFormGroup, BButton, BFormInput, BFormTextarea, BFormSelect, BFormSelectOption, BModal, BRow, VBModal } from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import store from '@/store';
import placementStoreModule from '@/views/apps/placement/placementStoreModule';
import { ref, onUnmounted } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useCourses from '../useCourses';
import vSelect from 'vue-select';

export default {
  name: 'CourseEditModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BModal,
    quillEditor,
    vSelect,
  },
  directives: { 'b-modal': VBModal },
  props: {
    createCourseActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    '$props.createCourseActive'(val) {
      this.initModal();
    },
  },
  created() {
    this.initModal();
  },
  methods: {
    initModal() {
      this.courseEditData = {
        name: null,
        avatar: null,
        visibility: 'public',
        status: 'created',
        metadata: {
          design: {
            color: null,
          },
        },
      };
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:create-course-active', false);
    },

    createEntity(event) {
      this.busy = true;

      event.preventDefault();

      const pl = { ...this.courseEditData };
      pl.visibility = pl.visibility.value;
      pl.status = pl.status.value;

      store
        .dispatch('rosterStoreModule/createCourse', { courseData: pl })
        .then((response) => {
          // this.$emit('update:course-data', response.data);

          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Course Created!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          this.$router.push('/roster/courses/' + response.data.id);
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create course',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  setup(props) {
    const PLACEMENT_STORE_NAME = 'placementStoreModule';

    const courseEditData = ref({});
    const busy = ref(false);

    const { statusOptions, visibilityOptions } = useCourses();

    return { statusOptions, visibilityOptions, courseEditData, busy };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
